<template>
  <div>
    <div
      class="bg"
      :style="
        'background:url(' +
        user.logo +
        ') no-repeat;background-size: 100% 100%;'
      "
    ></div>
    <div class="content">
      <div class="top">
        <img class="logo" :src="user.logo" />
        <h2 class="van-ellipsis">{{ user.title }}</h2>
        <div class="icon">
          <img
            src="https://dev-iot-v2.oss-cn-shenzhen.aliyuncs.com/market/picture/20220608_173538_62a06d6aa9cbc.png"
            alt="分享"
            @click="share"
          />
          <img
            src="https://dev-iot-v2.oss-cn-shenzhen.aliyuncs.com/market/picture/20220608_173604_62a06d84b4532.png"
            alt="设置"
            @click="myInfo"
          />
        </div>
      </div>
      <p class="desc">{{ user.desc }}</p>
      <p class="desc">{{ user.address_text }}</p>
      <div class="wallet">
        <p class="my">我的钱包</p>
        <div class="money">
          <p>¥{{ user.money }}</p>
          <p @click="moneyDetail">账单明细</p>
        </div>
        <div class="btn">
          <button class="btn-1" @click="withdrawal">提现</button>
          <button class="btn-2" @click="pay">充值</button>
        </div>
      </div>
    </div>

    <van-popup
      v-if="showPay"
      v-model:show="showPay"
      position="bottom"
      closeable
      :style="{ height: '30%' }"
    >
      <div class="pay">
        <div v-if="status">
          <van-icon name="success" color="#07c160" size="3rem" />
          <h3>恭喜,缴纳成功.</h3>
        </div>
        <div v-if="count !== 3 && !status">
          <h4>正在为您查询支付结果,请稍后...</h4>
          <van-loading color="#1989fa" size="65px" type="spinner" />
          <h4>第:{{ count }}次查询中...</h4>
        </div>
        <div v-else-if="count === 3 && !status">
          <van-icon name="cross" color="#ee0a24" size="3rem" />
          <h3>抱歉,缴纳失败.</h3>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { Row, Col, Icon, Cell, CellGroup, Popup, Loading } from 'vant'

export default {
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [Icon.name]: Icon,
    [Cell.name]: Cell,
    [Popup.name]: Popup,
    [Loading.name]: Loading,
    [CellGroup.name]: CellGroup,
  },
  data() {
    return {
      user: {},
      order_sn: null,
      timer: null,
      count: 0,
      status: false,
      showPay: false,
    }
  },
  created() {
    this.getUserInfo()
    const order_sn = this.$route.query.order_sn
    if (order_sn !== undefined && order_sn !== null) {
      this.order_sn = order_sn
      this.showPay = true
      this.timer = setInterval(this.checkPay, 3000)
    }
  },
  methods: {
    checkPay() {
      if (this.count === 3) {
        if (!this.status) {
          this.$notify({
            type: 'danger',
            message: '支付失败!',
            duration: 3000,
          })
        }
        setTimeout(this.getUserInfo, 1000)
        window.clearInterval(this.timer)
      } else {
        this.$http('/callback/order?order_sn=' + this.order_sn).then((res) => {
          if (res.data.pay_status === '2' || res.data.pay_status === 2) {
            this.status = true
            this.count = 3
            this.$notify({
              type: this.status ? 'success' : 'danger',
              message: this.status ? '支付成功!' : '支付失败!',
              duration: 5000,
            })
          }
        })
        this.count++
      }
    },
    getUserInfo() {
      this.$http('/merchant/info').then((res) => {
        this.user = res.data
        this.$state.user = res.data
      })
    },
    myInfo() {
      this.$router.push('myInfo')
    },
    moneyDetail() {
      this.$router.push('moneyDetail')
    },
    withdrawal() {
      this.$router.push('withdrawal')
    },
    share() {
      this.$router.push({ name: 'share', params: { info: false } })
    },
    pay() {
      this.$http({
        url: '/merchant/orderCreate',
        method: 'post',
      }).then((res) => {
        const order_sn = res.data.order_sn
        this.$http({
          url: '/merchant/pay',
          method: 'post',
          data: this.$http.adornData({ order_sn: order_sn }),
        }).then((res) => {
          this.order_sn = order_sn
          this.timer = setInterval(this.checkPay, 3000)
          window.location.href = res.data.mweb_url
        })
      })
    },
  },
}
</script>

<style lang="less" scoped>
.bg {
  background: url(https://img1.baidu.com/it/u=698938107,755743343&fm=253&fmt=auto&app=138&f=GIF?w=375&h=176)
    no-repeat;
  filter: blur(5px);
  background-size: 100%;
  height: 176px;
}

.pay {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  h3 {
    font-size: 25px;
    font-weight: bold;
  }
}
.content {
  background: #fafafa;
  padding: 0 32px;
  border-radius: 16px 16px 0 0;
  margin-top: -25px;
  height: calc(100vh - 200px);
  filter: blur(0px);
  .top {
    position: relative;
    height: 65px;
    .logo {
      width: 80px;
      height: 80px;
      position: absolute;
      bottom: 20px;
      object-fit: contain;
    }
    h2 {
      font-weight: bold;
      color: rgba(0, 0, 0, 0.85);
      position: absolute;
      bottom: 22px;
      padding: 0;
      margin: 0;
      left: 95px;
      width: 150px;
    }

    .icon {
      position: absolute;
      bottom: 20px;
      right: 0;
      img {
        width: 32px;
        height: 32px;
        padding-left: 8px;
      }
    }
  }
  .desc {
    font-size: 12px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.4);
    line-height: 20px;
    padding: 0;
    margin: 0;
    padding-bottom: 5px;
  }
  .wallet {
    text-align: center;
    height: 180px;
    background: #ffffff;
    border-radius: 8px 8px 8px 8px;
    .my {
      font-size: 12px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.4);
      line-height: 20px;
      padding-top: 15px;
    }
    .money {
      // display: flex;
      margin: 0 auto;
      // height: 44px;
      p:nth-child(1) {
        padding: 0;
        margin: 0;
        display: inline;
        font-size: 36px;
        font-weight: bold;
        // color: rgba(0, 0, 0, 0.85);
        line-height: 44px;
      }
      p:nth-child(2) {
        padding: 0;
        margin: 0;
        display: inline;
        position: absolute;
        padding-left: 8px;
        font-size: 10px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.4);
        line-height: 55px;
      }
    }
    .btn {
      padding-top: 36px;
      &-1 {
        width: 88px;
        height: 30px;
        background: #ffe6e0;
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
        border: none;
        margin-right: 22px;
        color: #f45950;
      }
      &-2 {
        color: #ffffff;
        width: 88px;
        height: 30px;
        background: linear-gradient(235deg, #f56f4d 0%, #f35450 100%);
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
        border: none;
      }
    }
  }
}
</style>
